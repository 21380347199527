import { Howl } from "howler";
import eagleSound from "../assets/sounds/eagle_cry.mp3";
import anthem from "../assets/sounds/american-anthem-gun-and-eagle_402OUVLh.mp3";

export const playEagleCry = () => {
    const sound = new Howl({
        src: [eagleSound],
    });
    sound.play();
};

// Call `playEagleCry()` when the conversion is successful
// Import your gunshot sound file

export const playAnthem = () => {
    const sound = new Howl({
        src: [anthem],
    });
    sound.play();
};