// src/components/SuccessAnimation.js
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";



const SuccessAnimation = ({ trigger }) => {
    const [isConfettiVisible, setConfettiVisible] = useState(false);

    useEffect(() => {
        if (trigger) {


            // Show confetti for a short duration
            setConfettiVisible(true);
            setTimeout(() => setConfettiVisible(false), 3000); // 3 seconds of confetti
        }
    }, [trigger]); // Only re-run when `trigger` changes

    return (
        <div>
            {/* Conditionally render confetti when `isConfettiVisible` is true */}
            {isConfettiVisible && (
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    numberOfPieces={300}
                    gravity={0.3}
                />
            )}
        </div>
    );
};

export default SuccessAnimation;
