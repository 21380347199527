// src/App.js
import React, { useState, useEffect } from "react";
import "./App.css";
import Converter from "./components/Converter";
import OutputDisplay from "./components/OutputDisplay";
import { convertToAmerican } from "./utils/convertUnits";
import { playAnthem, playEagleCry } from "./utils/sound";
import flagDivider from "./assets/eagle2.webp";
import SuccessAnimation from "./components/SuccessAnimation";

function App() {
    const [result, setResult] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [initialInput, setInitialInput] = useState("");
    const [initialType, setInitialType] = useState("distance");
    const [selectedType, setSelectedType] = useState("distance"); // Add selectedType state
    const [isMuted, setIsMuted] = useState(false);

    const handleConversion = (input, type) => {
        setSelectedType(type); // Update selectedType to track the current conversion type
        const americanResult = convertToAmerican(input, type);
        setResult(americanResult);

        if (!isMuted) playEagleCry();
        if (!isMuted) playAnthem();

        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 3000);

        const url = new URL(window.location);
        url.searchParams.set("input", input);
        url.searchParams.set("type", type);
        window.history.pushState({}, "", url);
    };

    const handleMuteToggle = () => {
        setIsMuted(!isMuted);
    };

    const handleTryAgain = () => {
        setResult(null);
        setInitialInput("");
        setInitialType("distance");

        const url = new URL(window.location);
        url.searchParams.delete("input");
        url.searchParams.delete("type");
        window.history.pushState({}, "", url);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const input = urlParams.get("input");
        const type = urlParams.get("type");

        if (input) setInitialInput(input);
        if (type) setInitialType(type);

        if (input && type && !sessionStorage.getItem("isProcessed")) {
            handleConversion(parseFloat(input), type);
            sessionStorage.setItem("isProcessed", "true");
        }

        const handleUnload = () => sessionStorage.removeItem("isProcessed");
        window.addEventListener("beforeunload", handleUnload);
        return () => window.removeEventListener("beforeunload", handleUnload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="App">
            <div className="content-container">
                <button onClick={handleMuteToggle} className="mute-button">
                    {isMuted ? "🔇" : "🔊"}
                </button>

                <h1>The Americanizer</h1>
                <div className="divider-container">
                    <img src={flagDivider} alt="American flag divider" className="divider-image" />
                </div>

                <div className="Converter">
                    <Converter
                        onConvert={handleConversion}
                        initialInput={initialInput}
                        initialType={initialType}
                    />
                </div>

                <SuccessAnimation trigger={isSuccess} />

                {result && (
                    <div className="OutputDisplay">
                        <OutputDisplay result={result} type={selectedType} /> {/* Use selectedType */}
                    </div>
                )}

                {result && (
                    <button
                        onClick={() => {
                            const shareUrl = window.location.href;
                            navigator.clipboard.writeText(shareUrl);
                            alert("Link copied to clipboard! Share it with your freedom-loving friends!");
                        }}
                        className="share-button"
                    >
                        Share Results
                    </button>
                )}

                {result && (
                    <button
                        onClick={handleTryAgain}
                        className="try-again-button"
                    >
                        Try Again
                    </button>
                )}
            </div>
        </div>
    );
}

export default App;
