const conversionData = {
    distance: [
        { unit: "Mall of Americas", rate: 451200, emoji: "🛍️" },
        { unit: "Grand Canyons", rate: 446000, emoji: "🏞️" },
        { unit: "Route 66 Segments", rate: 3945, emoji: "🛣️" },
        { unit: "Football Fields", rate: 100, emoji: "🏈" },
        { unit: "Statue of Liberties", rate: 93, emoji: "🗽" },
        { unit: "Walmart Aisles", rate: 50, emoji: "🏪" },
        { unit: "Bowling Alleys", rate: 19.2, emoji: "🎳" },
        { unit: "Eagle Wingspans", rate: 2.3, emoji: "🦅" },
        { unit: "Hot Dog Lengths", rate: 0.15, emoji: "🌭" },
        { unit: "Big Macs", rate: 0.1, emoji: "🍔" }
    ],
    volume: [
        { unit: "Baseball Stadiums", rate: 3000000, emoji: "⚾" },
        { unit: "Oil Barrels", rate: 159, emoji: "🛢️" },
        { unit: "Whiskey Barrels", rate: 200, emoji: "🥃" },
        { unit: "Bathtubs of Sweet Tea", rate: 150, emoji: "🛁" },
        { unit: "Kiddie Pools", rate: 400, emoji: "🌊" },
        { unit: "Milk Cartons", rate: 3.8, emoji: "🥛" },
        { unit: "Big Gulp Cups", rate: 1.9, emoji: "🥤" },
        { unit: "Bubba Kegs", rate: 1.1, emoji: "🍺" },
        { unit: "Apple Pies", rate: 0.95, emoji: "🥧" },
        { unit: "Red Solo Cups", rate: 0.47, emoji: "🍷" }
    ],
    weight: [
        { unit: "Ford F-150s", rate: 2268, emoji: "🚙" },
        { unit: "NFL Linebackers", rate: 113.4, emoji: "🏈" },
        { unit: "Kegs of Beer", rate: 58.7, emoji: "🍺" },
        { unit: "Thanksgiving Turkeys", rate: 7.26, emoji: "🦃" },
        { unit: "Bowling Balls", rate: 7.26, emoji: "🎳" },
        { unit: "Bald Eagles", rate: 6.35, emoji: "🦅" },
        { unit: "Six-Packs of Soda", rate: 2.3, emoji: "🥤" },
        { unit: "Jumbo Popcorn Buckets", rate: 1.5, emoji: "🍿" },
        { unit: "Cheeseburgers", rate: 0.25, emoji: "🍔" },
        { unit: "Pounds of Bacon", rate: 0.453, emoji: "🥓" }
    ],
    speed: [
        { unit: "NASCAR Cars", rate: 90, emoji: "🏎️" },
        { unit: "Monster Trucks", rate: 70, emoji: "🚚" },
        { unit: "Speedboats on Lake Havasu", rate: 40, emoji: "🚤" },
        { unit: "Bald Eagles in a Dive", rate: 40, emoji: "🦅" },
        { unit: "Roller Coasters", rate: 28, emoji: "🎢" },
        { unit: "Fourth of July Parade Floats", rate: 3.2, emoji: "🎆" },
        { unit: "Bicyclists with a Flag Cape", rate: 5, emoji: "🚴" },
        { unit: "Shopping Carts in Walmart", rate: 2, emoji: "🛒" }
    ],
    area: [
        { unit: "Las Vegas Casino Floors", rate: 30000, emoji: "🎰" },
        { unit: "Walmart Supercenters", rate: 18580, emoji: "🛒" },
        { unit: "Costco Warehouses", rate: 14450, emoji: "🛒" },
        { unit: "Football Fields", rate: 5351.2, emoji: "🏈" },
        { unit: "Rodeo Arenas", rate: 2700, emoji: "🤠" },
        { unit: "Bowling Alleys", rate: 2000, emoji: "🎳" },
        { unit: "Drive-In Theater Lots", rate: 1400, emoji: "🎥" },
        { unit: "Chuck E. Cheese Locations", rate: 1200, emoji: "🎉" },
        { unit: "Mini Golf Courses", rate: 1000, emoji: "⛳" },
        { unit: "Car Show Lots", rate: 900, emoji: "🚗" },
        { unit: "House Yards", rate: 800, emoji: "🏡" },
        { unit: "Baseball Diamonds", rate: 807, emoji: "⚾️" },
        { unit: "Basketball Courts", rate: 420, emoji: "🏀" },
        { unit: "McDonald's Playplaces", rate: 500, emoji: "🍟" },
        { unit: "Skateparks", rate: 500, emoji: "🛹" },
        { unit: "Buffalo Wild Wings Restaurants", rate: 300, emoji: "🍗" },
        { unit: "Starbucks Shops", rate: 170, emoji: "☕" },
        { unit: "American Diners", rate: 150, emoji: "🍔" },
        { unit: "Parking Spaces", rate: 15, emoji: "🅿️" },
        { unit: "Picnic Tables", rate: 5, emoji: "🍉" },
        { unit: "Subway Sandwich Tables", rate: 2.5, emoji: "🥪" },
        { unit: "Jukeboxes", rate: 1.5, emoji: "🎶" },
        { unit: "Coffee Tables", rate: 1, emoji: "☕" }
    ]
};

export default conversionData;
