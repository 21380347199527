import React, { useState, useEffect } from "react";

const Converter = ({ onConvert, initialInput, initialType }) => {
    const [inputValue, setInputValue] = useState(initialInput || "");
    const [unitType, setUnitType] = useState(initialType || "distance");
    const [errorMessage, setErrorMessage] = useState("");

    // Update state when props change (for URL-based initial load)
    useEffect(() => {
        if (initialInput) setInputValue(initialInput);
        if (initialType) setUnitType(initialType);
    }, [initialInput, initialType]);

    const handleConvert = () => {
        const parsedValue = parseFloat(inputValue);

        // Check for negative values
        if (parsedValue < 0) {
            setErrorMessage("Freedom lovers only think positive!");
            return;
        }

        setErrorMessage(""); // Clear any previous errors
        if (inputValue) {
            onConvert(parsedValue, unitType);
        }
    };

    return (
        <div>
            <h2>Enter metric value to Americanize</h2>
            {errorMessage && (
                <p style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</p>
            )}
            <input
                type="number"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter value (e.g., 100)"
            />
            <select
                value={unitType}
                onChange={(e) => setUnitType(e.target.value)}
            >
                <option value="distance">Distance [m]</option>
                <option value="volume">Volume [l]</option>
                <option value="weight">Weight [kg]</option>
                <option value="speed">Speed [km/h]</option>
                <option value="area">Area [sq m]</option>
            </select>
            <button onClick={handleConvert}>Convert</button>
        </div>
    );
};

export default Converter;
