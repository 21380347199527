// src/utils/convertUnits.js
import conversionData from "./conversionData";

export function convertToAmerican(input, type) {
    const conversions = conversionData[type];
    if (!conversions) {
        console.error(`Conversion type "${type}" not found.`);
        return "";
    }

    const result = [];
    let remainingInput = input;

    for (let { unit, rate } of conversions) {
        const quantity = Math.floor(remainingInput / rate);
        if (quantity > 0) {
            result.push(`${quantity} ${unit}`); // Keep unit name exactly as in conversionData
            remainingInput -= quantity * rate;
        }
        if (result.length === 3) break; // Stop once we have 3 parts
    }

    return result.join(', ');
}
