import React from "react";
import conversionData from "../utils/conversionData";

const OutputDisplay = ({ result, type }) => {
    const conversions = conversionData[type];

    if (!conversions) {
        return (
            <div>
                <h2>Americanized Result</h2>
                <p>Conversion type not found. Please check the input type.</p>
            </div>
        );
    }

    const resultLines = result.split(',').map((line, index) => {
// eslint-disable-next-line no-unused-vars
        const [_quantity, ...unitParts] = line.trim().split(' ');
        const unit = unitParts.join(' ').toLowerCase();

        // Find the matching conversion by checking unit name case-insensitively
        const conversion = conversions.find(
            conv => conv.unit.toLowerCase() === unit
        );

        // Use found emoji or default to a question mark emoji if not found
        const emoji = conversion ? conversion.emoji : "❓";

        return (
            <p key={index}>
                {emoji} {line.trim()}
            </p>
        );
    });

    return (
        <div>
            <h2>Americanized Result</h2>
            {resultLines}
        </div>
    );
};

export default OutputDisplay;
